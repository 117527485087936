import { Component , OnInit , Renderer2, Inject, PLATFORM_ID } from '@angular/core';

import { RouterLink, RouterOutlet } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ourAssetsModule } from '../../../components/HomeComponents/ourAssets/ourAssets.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SeoService } from '../../../services/seo/seo.service';
import { ToolsSectionModule } from '../../../components/ServiceComponent/ToolsSection/ToolsSection.module';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { ServiceVimeoComponent } from "../../../components/HomeComponents/service-vimeo/service-vimeo.component";
import { ServiceCaseStudyComponent } from "../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { ourAssetsComponent } from "../../../components/HomeComponents/ourAssets/ourAssets.component";
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-services',
    standalone: true,
    templateUrl: './services.component.html',
    styleUrl: './services.component.css',
    imports: [
    RouterLink,
    RouterOutlet,
    ourAssetsModule,
    ToolsSectionModule,
    SlickCarouselModule,
    FaqComponent,
    ServiceVimeoComponent,
    ServiceCaseStudyComponent,
    KnowBestComponent,
    ourAssetsComponent,
    CdnUrlDirective
]
})
export class ServicesComponent implements OnInit{
  cdnUrl = environment.cdnUrl;
  constructor(private meta: Meta,private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Ecommerce Support Services - Partner with FBSPL',
      description:
        "Explore tailored e-Commerce Outsourcing Services for your online store. Partner with us to streamline operations, enhance customer satisfaction, manage catalogs, customer service, data, orders, and more.",
      keywords: 'ecommerce outsourcing services, ecommerce outsourcing support',
      ogImage:'/E-commerce/Ecommerce Slider Image 01 (1).jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/e-commerce-outsourcing-services' });
  }
  slides = [
    {
      banner:true,
      img:`${this.cdnUrl}assets/E-commerce/Ecommerce Slider Image 01 (1).jpg `,
      img_mob:`${this.cdnUrl}assets/E-commerce/Mobile-banner11 (1).jpg`,
      Title: 'Respond Promptly to Your E-commerce Outsourcing Complexities ',
      Description:
      `
Leverage the best of technology with our comprehensive E-commerce outsourcing services. From expert pre-sales consultations to inventory management, we offer customized solutions best-fitted for your business needs.
  `,
      Ecommerce: 'Talk to our experts',
      Link:"/book-free-consultation-call",
      alt:'eCommerce Management Services '
      
    },
    {
      banner:false,
      img:`${this.cdnUrl}assets/E-commerce/Ecommerce Slider Image 02.jpg`,
      img_mob:`${this.cdnUrl}assets/E-commerce/Mobile-banner2.jpg`,
      Title: 'Revolutionizing E-commerce Brand with Fulfillment and Inventory Excellence  ',
      Description:
      'The collaboration led to significant improvements in sales, customer satisfaction, and operational efficiency. ',
      Ecommerce: 'Talk to our experts',
      Link:"/case-studies#ecommerce",
      alt:'Read How We Revolutionize eCommerce Brands-Case Study '
    },
    {
      banner:false,
      img:`${this.cdnUrl}assets/E-commerce/Ecom banner 1.jpg`,
      img_mob:`${this.cdnUrl}assets/E-commerce/Mobile-banner-1.png`,
      Title: 'Master the Art of Crafting E-commerce Product Descriptions That Make Customers Click "Add to Cart"',
      Description: "Read this blog to learn how to write product descriptions that address your customers' biggest pain points and compel them to click on the 'Buy' button.",
      Ecommerce: 'Talk to our experts',
      Link:"/blogs#ecommerce",
      alt:'eCommerce News & Blogs'
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: false // Enable arrows on tablets
        }
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false // Enable arrows on desktops
        }
      }
    ]
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
           { 
        "@type": "Question", 

      "name": "How can you assist me in revenue generation?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our multi-dimensional strategy enhances profitability by deeply analyzing your business, market trends, and target audience to create tailored plans and campaigns that capitalize on every opportunity." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do your ecommerce support services ensure I maintain a competitive edge?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We undertake thorough market evaluation and competitive benchmarking to recognize pivotal trends and innovative technologies and develop strategies that are bound to keep you at the forefront." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How does your ecommerce outsourcing team aid in customer nurturing and up-selling?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We begin this journey with a profound understanding of your ideal customers' needs and preferences. Then, proceed with tailoring email campaigns and loyalty initiatives. The final step involves stimulating revenue growth through data analysis and segmentation strategies." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do your ecommerce management services guarantee brand consistency across channels?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We recognize the importance of maintaining a cohesive and captivating brand voice across every platform. From creating solid brand governance protocols and guidelines to developing brand assets and communication templates that embody your identity, we help strengthen your brand image effectively." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for:  

<ul> 

<li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence." 

      } 

    } 
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "eCommerce Management", 

      "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/e-commerce-outsourcing-services`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
  }
}
