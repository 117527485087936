<section class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img appCdnUrl="assets/news/news_Banner.png" class="desktop-banner" alt="FBSPL Newsroom-Latest news, press releases, and more" />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">News</li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary pt-3">Newsroom</h1>
      <p class="card-text font-20-16 pt-3">
        Discover What’s New At FBSPL. Stay updated on trending events, <br />
        latest news, press releases, and more.
      </p>
    </div>
  </div>
</section>
<!-- Mobile View -->
<section class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img appCdnUrl="assets/news/News_mobile_banner.png" class="card-img-mob" alt="FBSPL Newsroom-Latest news, press releases, and more" />
    <div class="bannerTextMob">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">News</li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary">Newsroom</h1>
      <p class="card-text font-20-16 pt-3">
        Discover What’s New At FBSPL. Stay updated on trending events, <br />
        latest news, press releases, and more.
      </p>
    </div>
  </div>
</section>

<!-- Trending Events Section -->
<section class="container py-6">
  <h2 class="text-primary font-48-30" data-aos="slide-up">Trending Events</h2>
  <hr class="news_line d-none d-md-block" />
  <div class="container py-6">
    <div class="row mt-3">
      @for (news of newsData; track news; let i = $index) {
        <div class="col-lg-4 col-12 mt-3">
          <a [routerLink]="['/news', news.slug]">
            <div class="card trending_card" data-aos="zoom-in">
              <!-- Display image based on index -->
              <img
                class="card-img-top img-fluid"
                [src]="news.feature_image"
                alt={{news.feature_alt_tag}}
              [ngClass]="{
                'd-lg-block': [0, 2, 3, 5, 6, 8, 9, 11, 12, 14].includes(i),
                'd-none': [1, 4, 7, 10, 13].includes(i),
                'd-block d-lg-none': [1, 4, 7, 10, 13].includes(i)
              }"
                />
                <div class="card-body">
                  <h3 class="fs-24 fw-bolder">{{ news.title }}</h3>
                  <time class="fs-16">{{ news.publish_date }}</time>
                  <p class="fs-16">{{ news.short_description }}</p>
                </div>
                <!-- Conditional Image for bottom display -->
                <img
                  class="card-img-top img-fluid"
                  [src]="news.feature_image"
                  alt={{news.feature_alt_tag}}
              [ngClass]="{
                'd-lg-block': [1, 4, 7, 10, 13].includes(i),
                'd-none': [0, 2, 3, 5, 6, 8, 9, 11, 12, 14].includes(i)
              }"
                  />
                </div>
              </a>
            </div>
          }
        </div>
      </div>
    </section>
