<div class="container text-dark pt-5 d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/caseStudy/caseStudyBanner.jpg"
      class="desktop-banner"
      alt="FBSPL Case Studies"
      />
      <div class="bannerText" data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="./">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Case Studies
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-36-24 text-primary pt-3">Case Study</h1>
        <p class="card-text font-20-16 pt-3">
          Why do clients choose us? How do we overcome their challenges? Do we add
          any value to their processes? Do they recommend us? Read our case
          studies to find out.
        </p>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container text-dark slide d-block d-lg-none pt-5">
    <div class="gradientBannerMob">
      <img
        appCdnUrl="assets/caseStudy/caseStudyMobileBanner.jpg"
        class="card-img-mob"
        alt="FBSPL Case Studies"
        />
        <div class="bannerTextMob" data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="./">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Case Studies
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-36-24 text-primary">Case Study</h1>
          <p class="card-text font-20-16">
            Why do clients choose us? How do we overcome their challenges? Do we add
            any value to their processes? Do they recommend us? Read our case
            studies to find out.
          </p>
        </div>
      </div>
    </div>

    <section class="container pt-5">
      <div class="menu-container d-flex" data-aos="slide-up">
        @for (item of menuItems; track item) {
          <div class="menu-item d-flex">
            <button (click)="toggleMenu(item)" class="menu-button font-36-24">
              {{ item.name }}
              <span>
                @if (!item.isOpen) {
                  <img
                    appCdnUrl="assets/Icon/Layer_1plus vector.svg"
                    />
                }
                @if (item.isOpen) {
                  <img
                    appCdnUrl="assets/Icon/Group 31minus-vector.svg"
                    />
                }
              </span>
            </button>
          </div>
        }
      </div>
      @if (isAnyMenuOpen()) {
        <div>
          @for (item of menuItems; track item) {
            <div>
              @if (item.isOpen) {
                <div class="submenu d-flex">
                  @for (subItem of item.subItems; track subItem) {
                    <div
                      class="submenu-item"
                      data-aos="slide-up"
                      >
                      <a [routerLink]="'/case-studies'" [fragment]="subItem.href">
                        {{ subItem.text }}
                      </a>
                    </div>
                  }
                  <div class="submenu-item" data-aos="slide-up">
                    <a href="/case-studies"> All </a>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </section>

    <section class="container py-6" id="top" #top>
      @if (caseStudies && caseStudies.length && !APIerror) {
        <div class="row">
          @for (caseStudy of caseStudies; track caseStudy) {
            <div class="col-12 col-lg-6 col-md-6">
              <div class="" data-aos="zoom-in">
                <img
                  [src]="caseStudy.feature_image"
                  alt="{{caseStudy.feature_alt_tag}}"
                  class="img-fluid bordered"
                  />
                  <div class="mt-3">
                    <p class="fs-16 fw-bold mb-2">
                      {{ caseStudy.case_study_category.name }}
                    </p>
                    <h4 class="mb-3 font-28-20">{{ caseStudy.title }}</h4>
                    <a [routerLink]="['/case-studies', caseStudy.slug]" class="">
                      <h6 class="font-20 text-primary text-md-start mt-3 mb-5">
                        Learn More
                        <div class="headingCTA">
                          <button class="blueCTA podcast-card-cta"></button>
                        </div>
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
            }
          </div>
        }
        @if (APIerror) {
          <div>
            <h4 class="text-red font-36-24 text-center my-5">
              {{ Errormessage }}
            </h4>
          </div>
        }
        @if (pages.length >= 1) {
          <div class="row py-5">
            <nav>
              <ul class="pagination pagination-lg mb-0 place-content-center">
                <li
                  class="page-item"
                  aria-current="page"
                  (click)="changePage(currentPage - 1)"
                  [hidden]="currentPage === 1"
                  >
                  <span class="page-link px-3"> Previous</span>
                </li>
                @for (page of pages; track page) {
                  <li
                    class="page-item active"
                    aria-current="page"
                    (click)="changePage(page)"
          [ngClass]="{
            active: page === currentPage
          }"
                    >
                    <span class="page-link px-3"> {{ page }}</span>
                  </li>
                }
                <li
                  class="page-item"
                  aria-current="page"
                  (click)="changePage(currentPage + 1)"
                  [hidden]="currentPage === pages[pages.length - 1]"
                  >
                  <span class="page-link px-3"> Next</span>
                </li>
              </ul>
            </nav>
          </div>
        }
      </section>
