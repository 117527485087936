import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';

import { RouterLink, RouterOutlet } from '@angular/router';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SeoService } from '../../../services/seo/seo.service';
import { ToolsSectionModule } from '../../../components/ServiceComponent/ToolsSection/ToolsSection.module';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { ServiceVimeoComponent } from '../../../components/HomeComponents/service-vimeo/service-vimeo.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { ourAssetsModule } from '../../../components/HomeComponents/ourAssets/ourAssets.module';
import { ourAssetsComponent } from '../../../components/HomeComponents/ourAssets/ourAssets.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-services',
  standalone: true,
  templateUrl: './Insurance.component.html',
  styleUrl: './Insurance.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    ToolsSectionModule,
    FaqComponent,
    KnowBestComponent,
    ServiceVimeoComponent,
    ServiceCaseStudyComponent,
    ourAssetsModule,
    ourAssetsComponent,
    CdnUrlDirective
],
})
export class Insurance implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(private meta: Meta,private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title:
        'Insurance Business Process Outsourcing (BPO) Services | Insurance BPM Service Providers',
      description:
        "Discover efficient insurance BPM services! Get back-office support & insurance consultation from experts. Optimize insurance processes with FBSPL's reliable outsourcing solutions for agencies, MGAs, carriers & brokers. Act now!",
      keywords: 'insurance bpm, insurance bpo services, insurance back office',
      ogImage: '/insurance-main/Insurance-Slider Image-1.png',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/insurance-bpo-outsourcing' });
  }
  slides = [
    {
      banner: true,
      img: `${this.cdnUrl}assets/insurance-main/Insurance-Slider Image-1.png`,
      img_mob: `${this.cdnUrl}assets/insurance-main/Insurance-Slider ImageMob-1.png`,
      Title: ' Insurance Outsourcing Services',
      Description:
        'Deliver value beyond policies. Connect with us for customer-centric insurance outsourcing services. From policy processing to claims management, we have mastered it all. ',
      Bookaconsultation: 'Grow with Us',
      Link: '/book-free-consultation-call',
      alt:'FBSPL Insurance Outsourcing Services'
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/insurance-main/Insurance-Slider Image-2.png`,
      img_mob: `${this.cdnUrl}assets/insurance-main/Insurance-Slider ImageMob-2.png`,
      Title: 'Case Study',
      Description:
        'Witness the business transformation of our potential clients. Explore the significant impact on more than a thousand insurance agents, and businesses. ',
      Link: '/case-studies#insurance',
      alt:"FBSPL Insurance Case Study"
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/insurance-main/Insurance-Slider Image-3.png`,
      img_mob: `${this.cdnUrl}assets/insurance-main/Insurance-Slider ImageMob-3.png`,
      Title: 'Know What’s Trending in Insurance ',
      Description:
        'Stay ahead in the competitive insurance landscape! Discover the latest trends shaping the insurance industry, current market analysis, and more, to uncover opportunities for growth. ',
      Link: '/blogs#insurance',
      alt:"FBSPL Insurance Blogs & Latest Trends "
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: false, // Enable arrows on tablets
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
    ],
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "What are the benefits of insurance outsourcing services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We strive for client satisfaction and quality services. Here are the major perks businesses can avail while seeking insurance business process outsourcing services. 

<ul> 

<li>Cost-effective business solutions</li> 

<li>All-in-one expertise</li> 

<li>Maximized operational efficiency</li> 

<li>Improved customer experience</li> 

<li>Scalable solutions</li> 

<li>On-time delivery and 100% accuracy</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are the major technologies you have worked on?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Although our insurance BPO services are not restricted to a specific tool or technology, here are a few tools with which we have hands-on experience: 

<ul> 

<li>Applied Net</li> 

<li>India TM</li> 

<li>CSR 24</li> 

<li>AMS 360</li> 

<li>QQCatalyst</li> 

<li>PowerBroker</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the process to seek insurance outsourcing services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We follow a streamlined and transparent process to help businesses get the best results they’ve aimed for. Here’s how you can avail our insurance operations management support: 

<ul> 

<li>Book a consultation</li> 

<li>Review & assessment of agency needs</li> 

<li>Get customized services proposal</li> 

<li>Discussion of expectation and implementation</li> 

<li>Staff introduction</li> 

<li>Quarterly discussions of real-time results</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Will outsourcing insurance back-office services help cut operational costs?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Insurance back-office support and services are very time-consuming and costly. But, when you outsource back-office activities like policy processing, certifications, CMS updates, and more, you can save a lot of your time and focus on your business while we take care of your operations." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are your key differentiators?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "<ul> 

<li>Availability As Per Your Time Zone</li> 

<li>All-in One Expertise</li> 

<li>Dedicated Staff</li> 

<li>99% Accuracy</li> 

<li>Tailor-Made Solutions</li></ul>" 

      } 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Insurance BPO", 

      "item": "https://www.fbspl.com/services/insurance-bpo-outsourcing" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/insurance-bpo-outsourcing`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
