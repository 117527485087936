<section class="container py-5">
  <div class="row d-flex">
    <div class="col-12 col-md-4">
      <p class="font-20-16">
        Waiting for the right time? We must then tell you there is no perfect
        timing in this highly competitive world. Winners only have the edge of
        acting today. Talk to our leading industrial experts now!
      </p>
    </div>

    <div class="col-12 col-md-8 pe-md-4">
      <form autocomplete="off" [formGroup]="LetsConnectForm" (ngSubmit)="validateForm(LetsConnectForm)">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mt-3 mt-md-0">
              <input type="text" class="lets-form" formControlName="firstname" autocomplete="off" placeholder="Name"
                appNoNumbers [ngClass]="{
                  'is-invalid':
                    submitted && LetsConnectFormf['firstname'].errors
                }" />
                @if (submitted && LetsConnectFormf['firstname'].errors) {
                  <div class="invalid-feedback">
                    @if (
                      LetsConnectFormf['firstname'].errors[
                      'requiredButNotAllowBlank'
                      ]
                      ) {
                      <div>
                        *This Field is Required
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="mt-3">
                <input type="text" class="lets-form" formControlName="company" autocomplete="off"
                placeholder="Company Name" [ngClass]="{
                  'is-invalid': submitted && LetsConnectFormf['company'].errors
                }" />
                  @if (submitted && LetsConnectFormf['company'].errors) {
                    <div class="invalid-feedback">
                      @if (
                        LetsConnectFormf['company'].errors[
                        'requiredButNotAllowBlank'
                        ]
                        ) {
                        <div>
                          *This Field is Required
                        </div>
                      }
                    </div>
                  }
                </div>
                <div class="mt-3 phone-input-container">
                  <!-- Country Dropdown -->
                  <select class="lets-form country-select form-select" (change)="onCountryChange($event)" formControlName="country">
                    @for (country of countryList; track country) {
                      <option [value]="country.code" [attr.data-code]="country.code">
                        {{ country.name }} ({{ country.code }})
                      </option>
                    }
                  </select>

                  <!-- Phone Number Input -->
                  <input
                    type="text"
                    class="lets-form phone-number-input phn_padding"
                    formControlName="phone"
                    placeholder="Phone"
                    minlength="10"
                    appOnlyNumbers
                    maxlength="15"
                [ngClass]="{
                  'is-invalid': submitted && LetsConnectFormf['phone'].errors
                }"
                    />

                    <!-- Validation Messages -->
                    @if (submitted && LetsConnectFormf['phone'].errors) {
                      <div class="invalid-feedback">
                        @if (LetsConnectFormf['phone'].errors['requiredButNotAllowBlank']) {
                          <div>
                            *This Field is Required
                          </div>
                        }
                        @if (
                          !LetsConnectFormf['phone'].errors['requiredButNotAllowBlank'] &&
                          LetsConnectFormf['phone'].errors['exactLength']
                          ) {
                          <div
                            >
                            Invalid Phone
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mt-3 mt-md-0">
              <input type="email" class="lets-form" formControlName="email" placeholder="Email Address" [ngClass]="{
                  'is-invalid': submitted && LetsConnectFormf['email'].errors
                }" />
                      @if (submitted && LetsConnectFormf['email'].errors) {
                        <div class="invalid-feedback">
                          @if (
                            LetsConnectFormf['email'].errors['requiredButNotAllowBlank']
                            ) {
                            <div>
                              *This Field is Required
                            </div>
                          }
                          @if (LetsConnectFormf['email'].errors['email']) {
                            <div>
                              Invalid email address
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div class="mt-3">
              <textarea placeholder="Brief Requirement" formControlName="write_us" class="lets-form" rows="3" [ngClass]="{
                  'is-invalid': submitted && LetsConnectFormf['write_us'].errors
                }"></textarea>
                      @if (submitted && LetsConnectFormf['write_us'].errors) {
                        <div class="invalid-feedback">
                          @if (
                            LetsConnectFormf['write_us'].errors[
                            'requiredButNotAllowBlank'
                            ]
                            ) {
                            <div>
                              *This Field is Required
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <button class="FormCTA float-end">
                  <h6 class="font-48-30 text-primary text-md-end mt-4 mt-md-3">
                    Let’s Connect
                    <div class="headingCTA">
                      <div class="blueCTA podcast-card-cta"></div>
                    </div>
                  </h6>
                </button>
              </form>
            </div>
          </div>
          <div>
          </div>
        </section>